import { ready } from '#js/components/utils'

export function selectNavigationItem (location) {
  location = new URL(location || window.location.href)
  document.querySelectorAll('.nav-items a').forEach(function (element) {
    element.dataset.gtm = 'nav_item'
    if (location.pathname === new URL(element.href, location).pathname) {
      element.classList.add('selected')
    }
  })
}

export function mobileOpenNavigationGroupOnClick () {
  document.querySelectorAll('.nav-burger').forEach(function (element) {
    element.addEventListener('click', event => {
      element.classList.toggle('open')
      document.querySelectorAll('.nav-items').forEach(function (element) {
        element.classList.toggle('open')
      })
    })
  })
}

export function openNavigationGroupOnClick () {
  document.querySelectorAll('.nav-group-wrapper').forEach(function (element) {
    element.addEventListener('click', (event) => {
      element.classList.toggle('open')
    })
  })
}

export function closeNavigationGroupOnMouseUp () {
  document.addEventListener('click', (event) => {
    try {
      if (event.target.closest('.nav-group-wrapper.open') === null) {
        document.querySelectorAll('.nav-group-wrapper.open').forEach(function (element) {
          element.classList.remove('open')
        })
      }
    } catch (error) { // event.target is not an Element
      document.querySelectorAll('.nav-group-wrapper.open').forEach(function (element) {
        element.classList.remove('open')
      })
    }
  })
}

export function markCurrentNavItems (location) {
  location = new URL(location || window.location.href)
  document.querySelectorAll('a.nav__item, a.nav__link').forEach(function (element) {
    const url = new URL(element.href, location)
    if (location.pathname.startsWith(url.pathname)) {
      if (url.hash) {
        element.setAttribute('aria-current', 'page')
        if (document.querySelector(url.hash)) {
          new IntersectionObserver(function (entries) {
            entries.forEach(function (entry) {
              if (entry.isIntersecting) {
                element.setAttribute('aria-current', 'location')
              } else {
                element.removeAttribute('aria-current', 'page')
              }
            })
          }, { rootMargin: '0px 0px -100% 0px' }).observe(document.querySelector(url.hash))
        }
      } else {
        if (location.hash || location.pathname !== url.pathname) {
          element.setAttribute('aria-current', 'page')
        } else {
          element.setAttribute('aria-current', true)
        }
      }
      const navGroup = element.closest('.details')
      if (navGroup) {
        navGroup.open = true
        navGroup.querySelector('.nav__summary').setAttribute('aria-current', 'page')
      }
    }
  })
}

ready(function () {
  markCurrentNavItems()
  selectNavigationItem()
  openNavigationGroupOnClick()
  mobileOpenNavigationGroupOnClick()
  closeNavigationGroupOnMouseUp()
})
